<!-- Start of Selection -->
<template>
  <b-modal id="modal-edit-harga" ref="modalEditHarga" title="Edit Harga" size="lg" scrollable ok-title="Update"
    @ok="updateProducts" @hidden="resetForm">
    <b-row v-if="productData.length === 0" class="mb-4">
      <b-col>
        <p>Tidak ada data produk untuk ditampilkan.</p>
      </b-col>
    </b-row>
    <b-row v-for="(item, index) in productData" :key="index" class="mb-4">
      <b-col md="2">
        <b-img :src="getProductImage(item)" alt="Gambar Produk" thumbnail />
      </b-col>
      <b-col md="5">
        <h6>{{ item.product.basic.name }}</h6>
        <p>Toko: {{ item.shopName }}</p>
      </b-col>
      <b-col md="5">
        <b-table-simple striped responsive>
          <b-thead>
            <b-tr>
              <b-th>Variasi</b-th>
              <b-th>SKU</b-th>
              <b-th>Harga Lama</b-th>
              <b-th>Harga Baru</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(variation, vIndex) in item.product.variations" :key="vIndex">
              <b-td>{{ variation.name }}</b-td>
              <b-td>{{ variation.sku }}</b-td>
              <b-td>{{ formatPrice(variation.price.rupiah) }}</b-td>
              <b-td>
                <b-form-input v-model.number="variation.newPrice" type="number" placeholder="Masukkan harga baru"
                  @input="validatePrice(variation)"></b-form-input>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
    <template #modal-footer="{ ok, cancel }">
      <b-button variant="secondary" @click="cancel()">Batal</b-button>
      <b-button variant="primary" @click="ok()">Update</b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BMedia,
  BImg,
  BTableSimple,
  BTh,
  BTr,
  BTd,
  BTbody,
  BThead,
  BButton,
  BCard,
  BTabs,
  BTab,
} from 'bootstrap-vue';

export default {
  components: {
    BButton, // Daftarkan komponen BButton
    BRow,
    BCol,
    BModal,
    BMedia,
    BImg,
    BTableSimple,
    BTh,
    BTr,
    BTd,
    BTbody,
    BThead,
    BButton,
    BCard,
    BTabs,
    BTab,
  },
  data() {
    return {
      productData: [], // Pastikan productData didefinisikan
    };
  },
  methods: {
    getProductImage(item) {
      return item.product.pictures[0]?.ThumbnailURL || 'https://via.placeholder.com/150x150';
    },
    formatPrice(price) {
      return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(price);
    },
    validatePrice(variation) {
      if (variation.newPrice < 0) {
        variation.newPrice = 0;
      }
    },
    updateProducts() {
      // Implementasi logika untuk memperbarui produk
    },
    resetForm() {
      // Reset form setelah modal ditutup
      this.productData.forEach(item => {
        item.product.variations.forEach(variation => {
          variation.newPrice = null; // Reset harga baru
        });
      });
    },
  },
};
</script>
<!-- End of Selection -->